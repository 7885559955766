import { Slideshow } from "./slideshow";

export const Gallery = (props) => {
  return (
    <div id="portfolio" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Gallery</h2>
        </div>
        <div className="row">
          <div className="portfolio-items">
            {props.data
              ? props.data.map((d, i) => (
                  <div
                    key={`${d.title}-${i}`}
                    className="col-sm-6 col-md-4 col-lg-4"
                  >
                    <Slideshow fadeImages={d.fadeImages} />
                  </div>
                ))
              : "Loading..."}
          </div>
        </div>
      </div>
      <div>
        <br></br>
        <br></br>
        <a
          href="https://www.instagram.com/jackoffades39"
          target="_blank"
          rel="noreferrer"
          className="btn btn-custom"
        >
          View more on Instagram <i className="fa fa-instagram"></i>
        </a>
      </div>
    </div>
  );
};
