export const ServiceElement = ({ serviceName, description, price }) => (
  <div className="row">
    <center>
      <table>
        <tr>
          <td>
            <h3>{serviceName}</h3>
          </td>
          <td>
            <strong>{price}</strong>
          </td>
        </tr>
        <tr>
          <td>{description}</td>
          <td></td>
        </tr>
      </table>
    </center>
    <hr></hr>
  </div>
);
