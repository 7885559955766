export const Contact = (props) => {
  return (
    <div>
      <div id="contact">
        <div className="container">
          <div className="row">
            <div className="section-title">
              <h2>Get In Touch</h2>
            </div>
          </div>

          <div className="row">
            <div class="col-md-6 col-md-offset-1">
              <center>
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2798.621874044554!2d-73.57390468508264!3d45.45727534192755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4cc9110e8466e20d%3A0xf5c257522c12bda1!2sJack%20Of%20Fades!5e0!3m2!1sen!2sca!4v1664310320141!5m2!1sen!2sca"
                  width="100%"
                  title="Location"
                  height="450"
                  style={{ border: "0", alignContent: "center" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                ></iframe>
              </center>
            </div>

            <div className="col-md-3 col-md-offset-1 contact-info">
              <center>
                <h3>Contact Info</h3>
              </center>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-map-marker"></i> Address
                  </span>
                  {props.data ? props.data.address : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {props.data ? props.data.phone : "loading"}
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> Email
                  </span>{" "}
                  {props.data ? props.data.email : "loading"}
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row">
              <div className="social">
                <ul>
                  <li>
                    <a
                      href={props.data ? props.data.facebook : "/"}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a
                      href={props.data ? props.data.instagram : "/"}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        <div className="container text-center">
          <p>
            &copy; 2022 Jack Of Fades. Developed by{" "}
            <a
              href="https://www.linkedin.com/in/athwal-yuvraj"
              rel="noreferrer"
              target="_blank"
            >
              Yuvraj Singh Athwal
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};
