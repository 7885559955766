import { ServiceElement } from "./service";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
        </div>
        <div className="row">
          <div className="col-md-6 col-sm-6">
            <ServiceElement
              serviceName={"Haircut"}
              price={"$20-$35"}
              description={"(30m) Fade/Taper & Scissor-cut"}
            />

            <ServiceElement
              serviceName={"Haircut & Beard"}
              price={"$35-$50"}
              description={"(45m) Fade/Taper & Scissor-cut including Beard"}
            />

            <ServiceElement
              serviceName={"Line-up & Beard"}
              price={"$25"}
              description={"(30m) Hair line up including Beard."}
            />
            <ServiceElement
              serviceName={"Kid's Haircut"}
              price={"$25"}
              description={"(30m)"}
            />
          </div>
          <div className="col-md-6 col-sm-6">
            <ServiceElement
              serviceName={"Hair Color with Haircut"}
              price={"$120"}
              description={"(2h) Hair color of choice with a Haircut"}
            />
            <ServiceElement
              serviceName={"Highlights"}
              price={"$50"}
              description={"(1h) Highlights of any color"}
            />
            <ServiceElement
              serviceName={"Hair Treatment"}
              price={"$120"}
              description={"(2.5h) Keratin, Smoothening or Perms(+$20)"}
            />
            <ServiceElement
              serviceName={"Face wax"}
              price={"$10-$15"}
              description={"(15m) Face, nose, ears & eyebrows"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
