export const Testimonials = (props) => {
  return (
    <div id="testimonials">
      <div className="container">
        <div className="section-title text-center">
          <h2>What our clients say</h2>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  <div className="testimonial">
                    <div className="testimonial-image">
                      {" "}
                      <img src={d.img} alt="" />{" "}
                    </div>
                    <div className="testimonial-content">
                      <p>"{d.text}"</p>
                      <div className="testimonial-meta"> - {d.name} </div>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
      <div>
        <br></br>
        <br></br>
        <center>
          <a
            href="https://search.google.com/local/reviews?placeid=ChIJDeJmhA4RyUwRob0SLFJXwvU&q=Jack+Of+Fades&hl=en&gl=CA"
            target="_blank"
            rel="noreferrer"
            className="btn btn-custom"
          >
            View more on Google{" "}
            <i className="fa fa-google" aria-hidden="true"></i>
          </a>
        </center>
      </div>
    </div>
  );
};
