export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>The best barbers at your service</h2>
          <p>
            Our artists are on the lookout for the latest trends and perform
            their art in respect of your image and your preferences.
          </p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <center>
                    <div className="thumbnail">
                      {" "}
                      <a href={d.url} target="_blank" rel="noreferrer">
                        <img src={d.img} alt="..." className="team-img" />

                        <div class="content">
                          <div class="text">
                            View Instagram
                            <br></br>
                            <i className="fa fa-instagram"></i>
                          </div>
                        </div>
                      </a>
                      <div className="caption">
                        <h4>{d.name}</h4>
                      </div>
                    </div>
                  </center>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
